import Format from "./wilhelm/Format";
import clsx from "clsx";
import React from "react";

// eslint-disable-next-line react/display-name
const Input = React.forwardRef(({ ...props }, ref) => {
  const Element = props.element ?? "input";
  const inputProps = {
    ...props,
    id: `_${props.name}`,
    label: undefined,
    element: undefined,
    error: undefined,
    className: undefined,
    icon: undefined,
  };

  const children = props.options
    ? Object.entries(props.options).map(([id, option]) => (
        <option key={`option_${id}`} value={id}>
          {option}
        </option>
      ))
    : null;

  return (
    <div className={clsx("tw-relative", props.className)}>
      <Element
        ref={ref}
        {...inputProps}
        className={clsx(
          "tw-form-input tw-bg-grey-darkest tw-text-white tw-block tw-px-4 tw-py-4 tw-w-full tw-text-gray-900 tw-bg-transparent tw-border tw-border-grey-medium tw-appearance-none tw-transition-colors tw-peer",
          "focus:tw-outline-none focus:tw-ring-0 focus:tw-border focus:tw-border-grey-soft placeholder:tw-text-grey-medium",
          "w-o-format-body-large",
          {
            "tw-border-red-main": !!props.error,
            "tw-min-h-[10rem]": props.element === "textarea",
            "tw-pr-12": !!props.icon,
          }
        )}
        placeholder={props.placeholder ?? ""}
      >
        {children}
      </Element>
      <label
        htmlFor={props.id}
        className={clsx(
          "w-o-format-body-large",
          "tw-absolute tw--translate-y-4 tw-text-grey-medium tw-bg-grey-darkest tw-scale-75 tw-top-1 tw-z-10 tw-origin-[0] tw-px-1 tw-left-[1px] tw-pointer-events-none tw-max-w-[80%] tw-duration-300 tw-transform",
          "peer-focus:tw-px-1 peer-focus:tw-text-white peer-focus:tw-top-1 peer-focus:tw-scale-75 peer-focus:tw--translate-y-4",
          {
            "peer-placeholder-shown:tw-scale-100 peer-placeholder-shown:tw-px-4 peer-placeholder-shown:tw-top-1/2 peer-placeholder-shown:tw--translate-y-1/2":
              props.element !== "textarea" && !props.placeholder,
            "peer-placeholder-shown:tw-scale-100 peer-placeholder-shown:tw-px-4 peer-placeholder-shown:tw-top-4 peer-placeholder-shown:tw--translate-y-0":
              props.element === "textarea" && !props.placeholder,
            "peer-placeholder-shown:tw-top-1 peer-placeholder-shown:tw--translate-y-4":
              props.placeholder,
          }
        )}
      >
        {props.label}
      </label>
      {props.icon && (
        <props.icon.type
          {...props.icon.props}
          className={
            props.icon.props.className +
            " tw-pointer-events-none tw-absolute tw-top-1/2 tw--translate-y-1/2 tw-right-4"
          }
        />
      )}
      {/* {props.error && (
        <Format preset="body-small" as="p" className="tw-text-red-main" role="alert">
          {props.error?.message}
        </Format>
      )} */}
    </div>
  );
});
export default Input;
