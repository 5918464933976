import { ReactComponent as CloseIcon } from "../icons/close.svg";
import Spinner from "./Spinner";
import Button from "./wilhelm/Button";
import { Dialog, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import React, { Fragment, isValidElement, cloneElement } from "react";

export default function ModalDrawer(props) {
  const innerClassName = clsx("tw-select-none tw-flex-1", props.innerClassName, {
    "tw-overflow-y-auto tw-overscroll-y-contain": props.scrollable,
  });

  const loading = props.loading ?? false;

  const Element = props.outerElement ?? "div";

  return (
    <>
      <Transition appear={true} show={props.isOpen} as={Fragment}>
        <Dialog
          as="div"
          unmount={false}
          className="tw-relative tw-z-[1000]"
          onClose={props.closeFn}
        >
          <Transition.Child
            as={Fragment}
            enter="tw-ease-out tw-duration-300"
            enterFrom="tw-opacity-0"
            enterTo="tw-opacity-100"
            leave="tw-ease-in tw-duration-200"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
          >
            <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-25" />
          </Transition.Child>

          <Element
            className="tw-fixed tw-inset-y-0 tw-right-0 tw-antialiased tw-font-text tw-flex tw-items-end"
            {...props.outerProps}
          >
            <Transition.Child
              as={Fragment}
              enter="tw-ease-out tw-duration-300"
              enterFrom="tw-opacity-0 tw-translate-x-full"
              enterTo="tw-opacity-100 tw-translate-x-0"
              leave="tw-ease-in tw-duration-200"
              leaveFrom="tw-opacity-100 tw-translate-x-0"
              leaveTo="tw-opacity-0 tw-translate-x-full"
            >
              <Dialog.Panel
                className={clsx(
                  "tw-relative tw-h-full tw-w-full tw-min-w-[20em] tw-max-w-md tw-mx-auto tw-flex tw-flex-col tw-transform tw-overflow-hidden tw-text-left tw-transition-all tw-bg-grey-darkest tw-text-white",
                  {
                    "tw-cursor-wait": loading,
                  }
                )}
              >
                <button
                  className="tw-absolute tw-z-10 tw-right-0 tw-top-0 tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-bg-red-main"
                  onClick={props.closeFn}
                >
                  <CloseIcon className="tw-w-3 tw-h-3 tw-fill-white" />
                </button>
                <div className={innerClassName}>{props.children}</div>

                {props.buttons && (
                  <div className={`tw-relative tw-flex-none tw-border-t tw-border-grey-medium`}>
                    {props.buttons}
                  </div>
                )}
                {loading && (
                  <div className="tw-absolute tw-inset-0 tw-z-40 tw-bg-black/25">
                    <Spinner className="tw-absolute tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2" />
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </Element>
        </Dialog>
      </Transition>
    </>
  );
}
